export default class Insured 
{
    rfc= "";
    name= "";
    paternal_name= "";
    maternal_name= "";
    street= "";
    suburb= "";
    postal_code= "";
    city= "";
    state= "";
    state_code= "";
    email= "";
    curp= "";
    ext_number= "";
    int_number= "";
    phone= "";
    cellphone= "";
    municipality= "";
    marital_status= "";
    public_office= "";
    country= "";
    occupation= "";
    city_of_birth= "";
    state_name_birth= "";
    date_birth= "";
    height= "";
    weight= "";
    gender= "";

    constructor(insured){
        const keys = Object.keys((insured==null)?{}:insured);
        this.rfc = (keys.includes("insured_rfc")? insured.insured_rfc: "");
        this.name = (keys.includes("insured_name")? insured.insured_name: "");
        this.paternal_name = (keys.includes("insured_paternal_name")? insured.insured_paternal_name: "");
        this.maternal_name = (keys.includes("insured_maternal_name")? insured.insured_maternal_name: "");
        this.street = (keys.includes("insured_street")? insured.insured_street: "");
        this.suburb = (keys.includes("insured_suburb")? insured.insured_suburb: "");
        this.postal_code = (keys.includes("insured_postal_code")? insured.insured_postal_code: "");
        this.city = (keys.includes("insured_city")? insured.insured_city: "");
        this.state = (keys.includes("insured_state")? insured.insured_state: "");
        this.state_code = (keys.includes("insured_state_code")? insured.insured_state_code: "");
        this.email = (keys.includes("insured_email")? insured.insured_email: "");
        this.curp = (keys.includes("insured_curp")? insured.insured_curp: "");
        this.ext_number = (keys.includes("insured_ext_number")? insured.insured_ext_number: "");
        this.int_number = (keys.includes("insured_int_number")? insured.insured_int_number: "");
        this.phone = (keys.includes("insured_phone")? insured.insured_phone: "");
        this.cellphone = (keys.includes("insured_cellphone")? insured.insured_cellphone: "");
        this.municipality = (keys.includes("insured_municipality")? insured.insured_municipality: "");
        this.marital_status = (keys.includes("insured_marital_status")? insured.insured_marital_status: "");
        this.public_office = (keys.includes("insured_public_office")? insured.insured_public_office: "");
        this.country = (keys.includes("insured_country")? insured.insured_country: "");
        this.occupation = (keys.includes("insured_occupation")? insured.insured_occupation: "");
        this.city_of_birth = (keys.includes("insured_city_of_birth")? insured.insured_city_of_birth: "");
        this.state_name_birth = (keys.includes("insured_state_name_birth")? insured.insured_state_name_birth: "");
        this.date_birth = (keys.includes("insured_date_birth")? insured.insured_date_birth: "");
        this.height = (keys.includes("insured_height")? insured.insured_height: "");
        this.weight = (keys.includes("insured_weight")? insured.insured_weight: "");
        this.gender = (keys.includes("insured_gender")? insured.insured_gender: "");
    }
}