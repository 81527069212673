const host = process.env.VUE_APP_GSAPI;
const host_ccapi = process.env.VUE_APP_CCAPI;
const key = process.env.VUE_APP_GSAPI_KEY;

import api from '@/api/api';
import { getToken as getTokenFromStorage } from '@/helpers/tokenauth.js'; 
import Quote from '@/classes/Quotation.js';
import Address from '@/classes/Address.js';
import Collection from '@/classes/Collection.js';
import Work from '@/classes/Work.js';
import Insured from '@/classes/Insured.js';
import Contractor from '@/classes/Contractor.js';
export default class Issuance {
    
    constructor(issuance){
        const keys = Object.keys((issuance==null)?{}:issuance);
        this.quote = new Quote(issuance);
        this.address = new Address(issuance);
        this.collection = new Collection(issuance);
        this.work = new Work(issuance);
        this.contractor = new Contractor(issuance);
        this.insured = new Insured(issuance);
        this.documents_list = (keys.includes("documents_list"))? issuance.documents_list : [];
        this.beneficiaries = (keys.includes("beneficiaries"))? issuance.beneficiaries : [];
    }

    createIssuance = async(token, params, url) => {
        const config = this.getHeaders(token);
        return await api.httpPost(url, config, params);
    }

    getHeaders(token){
        return {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };
    }

    get(type){
        return this.getStorage(type);
    }

    save(type, value){
        this.saveStorage(type, value)
    }

    getStorage(type){
        return JSON.parse(localStorage.getItem(`issuance-${type}`)) || {};
    }

    saveStorage(type, value){
        return localStorage.setItem(`issuance-${type}`, JSON.stringify(value));
    }

    create = async(token, params) => {

        const resource = `/api/v1/vs/application/create`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        return await api.httpPost(API_URL, config, params);
    }

    getToken = async() => {
        const resource = `/token/generate`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            apikey: key
        };

        return await api.httpGet(API_URL, config , params);
    }

    uploadFiles = async (token, files) => {

        const resource = '/documents/upload'
        const API_URL = `${host}${resource}`

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        const params = {
            documents: files,
            process_name: "vida_simple"
        }

        try {
            return await api.httpPost(API_URL, config, params);
        } catch (error) {
            throw error.response;
        }

    }

    deleteFiles = async (token, files) => {
        const resource = '/documents/delete';
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        const params = {
            documents_urls: files,
            process_name: "vida_simple"
        }

        try {
            return await api.httpPost(API_URL, config, params);
        } catch (error) {
            throw error.response;
        }
    }

    sendDocumenToSigned = async ({product_name, rfc, vs_issue_policy_id, solicitud_id, url_back}) => {
        const resource = '/send_document_to_sign';
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${getTokenFromStorage()}`
            }
        };

        const params = {
            "product_name": product_name,
            "rfc": rfc,
            "vs_issue_policy_id": vs_issue_policy_id,
            "solicitud_id": solicitud_id,
            "url_back": url_back
        };

        try {
            return await api.httpPost(API_URL, config, params);
        } catch (error) {
            throw error.response;
        }
    }

    sendSMS = async ({token, phone_number, message}) => {
        const resource = '/send_sms';
        const API_URL = `${host_ccapi}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        const params = {
            "token_auth": token,
            "phone_number": phone_number,
            "message": message,
        };

        try {
            return await api.httpPost(API_URL, config, params);
        } catch (error) {
            throw error.response;
        }
    }

}