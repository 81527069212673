export default class Contractor 
{
    rfc= null;
    name= null;
    paternal_name= null;
    maternal_name= null;
    street= null;
    ext_number= null;
    int_number= null;
    suburb= null;
    postal_code= null;
    city= null;
    state= null;
    email= null;
    curp= null;
    gender= null;
    municipality= null;
    phone= null;
    cellphone= null;
    occupation= null;
    country= null;
    company_name= null;

    constructor(contractor){
        const keys = Object.keys((contractor==null)?{}:contractor);
        this.rfc = (keys.includes("contractor_rfc")? contractor.contractor_rfc: "")
        this.name = (keys.includes("contractor_name")? contractor.contractor_name: "")
        this.paternal_name = (keys.includes("contractor_paternal_name")? contractor.contractor_paternal_name: "")
        this.maternal_name = (keys.includes("contractor_maternal_name")? contractor.contractor_maternal_name: "")
        this.street = (keys.includes("contractor_street")? contractor.contractor_street: "")
        this.ext_number = (keys.includes("contractor_ext_number")? contractor.contractor_ext_number: "")
        this.int_number = (keys.includes("contractor_int_number")? contractor.contractor_int_number: "")
        this.suburb = (keys.includes("contractor_suburb")? contractor.contractor_suburb: "")
        this.postal_code = (keys.includes("contractor_postal_code")? contractor.contractor_postal_code: "")
        this.city = (keys.includes("contractor_city")? contractor.contractor_city: "")
        this.state = (keys.includes("contractor_state")? contractor.contractor_state: "")
        this.email = (keys.includes("contractor_email")? contractor.contractor_email: "")
        this.curp = (keys.includes("contractor_curp")? contractor.contractor_curp: "")
        this.gender = (keys.includes("contractor_gender")? contractor.contractor_gender: "")
        this.municipality = (keys.includes("contractor_municipality")? contractor.contractor_municipality: "")
        this.phone = (keys.includes("contractor_phone")? contractor.contractor_phone: "")
        this.cellphone = (keys.includes("contractor_cellphone")? contractor.contractor_cellphone: "")
        this.occupation = (keys.includes("contractor_occupation")? contractor.contractor_occupation: "")
        this.country = (keys.includes("contractor_country")? contractor.contractor_country: "")
        this.company_name = (keys.includes("contractor_company_name")? contractor.contractor_company_name: "")
    }
}